<template>
  <div class="orders-list-page">
    <v-orders-list />
  </div>
</template>

<script>
import { call } from "vuex-pathify";
import VOrdersList from "../../components/orders/VOrdersList";

export default {
  name: "OrdersList",

  layout: "default",

  components: {
    VOrdersList,
  },

  metaInfo() {
    return { title: "Orders" };
  },

  created() {
    this.deleteOrdersByGroup("orders");
  },

  methods: call("notifications", {
    deleteOrdersByGroup: "deleteByGroup",
  }),
};
</script>

<style lang="scss" scoped>
.orders-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.orders-list-description {
  margin: 0;
}
</style>
