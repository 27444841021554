var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-list"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"options":_vm.options,"items-per-page":25,"server-items-length":_vm.total,"loading":_vm.isLoading,"footer-props":{ 'items-per-page-options': [25, 50, 100, -1] },"sort-by":"sentAt","sort-desc":true,"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.sentAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("dateOrderFormat")(value))+" ")]}},{key:"item.tableNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTableNamber(item))+" ")]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.$options.filters.normalizePrice(value) + " " + (item.priceCurrency ? item.priceCurrency : "")))]),(_vm.isValidateTotalPrice(value, item.validatedPrice))?_c('v-chip',{staticClass:"ml-2",attrs:{"color":"red","text-color":"white","label":"","small":""}},[_vm._v(" ERROR ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var order = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"order-additional-info"},[(order.takeAway && order.orderType === 'TAKE-AWAY')?_c('div',{staticClass:"take-away-grid"},[_c('div',{staticClass:"take-away-item"},[_c('b',[_vm._v("Customer Name")]),_c('p',[_vm._v(_vm._s(order.takeAway.customerName))])]),_c('div',{staticClass:"take-away-item"},[_c('b',[_vm._v("Customer Phone")]),_c('p',[_c('a',{attrs:{"href":("tel:" + (order.takeAway.tel.replace(/[\s\-]+/g, '')))}},[_vm._v(" "+_vm._s(order.takeAway.tel)+" ")])])]),_c('div',{staticClass:"take-away-item"},[_c('b',[_vm._v("Time")]),_c('p',[_vm._v(_vm._s(_vm._f("dateOrderFormat")(order.takeAway.takeAwayTime)))])])]):_vm._e(),(order.comment)?_c('div',[_c('b',[_vm._v("Comment")]),_c('p',[_vm._v(_vm._s(order.comment))])]):_vm._e()]),_c('table',{staticClass:"table-items"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("Menu.ItemID")))]),_c('th',[_vm._v(_vm._s(_vm.$t("GlobalUI.Title")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Menu.Options")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Menu.Extras")))]),_c('th',[_vm._v(_vm._s(_vm.$t("GlobalUI.Comment")))]),_c('th',[_vm._v(_vm._s(_vm.$t("GlobalUI.Quantity")))]),_c('th',[_vm._v(_vm._s(_vm.$t("GlobalUI.Price")))])]),_vm._l((order.items),function(ref,idx){
var item = ref.item;
var quantity = ref.quantity;
return _c('tr',{key:((item.id) + "-" + idx)},[_c('td',[_vm._v(_vm._s(item.internalId || "–"))]),_c('td',[_vm._v(_vm._s(item.title[_vm.appSettings.defaultLanguage]))]),_c('td',[_vm._l((item.options),function(option){return [(option.selected)?_c('span',{key:option.id},[_vm._v(" "+_vm._s(option.title[_vm.appSettings.defaultLanguage] || '–')+" ")]):_vm._e()]})],2),_c('td',[(item.extras.length)?_vm._l((_vm.filterSelectedExtras(item.extras)),function(extra){return _c('span',{key:extra.id,staticClass:"d-block"},[_vm._v(" "+_vm._s(extra.title[_vm.appSettings.defaultLanguage])+" "+_vm._s(extra.single ? " — 1x" : "")+" "+_vm._s(extra.double ? " — 2x" : "")+" ")])}):[_vm._v("–")]],2),_c('td',[_vm._v(_vm._s(item.comment || "—"))]),_c('td',[_vm._v(_vm._s(quantity))]),_c('td',[_vm._v(_vm._s(_vm.$options.filters.normalizePrice(quantity * item.priceValue))+" "+_vm._s(order.priceCurrency))])])})],2)])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-order-status-picker',{attrs:{"order":item},on:{"change":_vm.handleChangeStatus}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }