<template>
  <v-select
    ref="orderStatusPicker"
    :value="order.status"
    :items="orderStatuses"
    @click.stop
    @change="handleChangeStatus($event, order.id)"
    @input="$refs.orderStatusPicker.blur()"
    item-value="key"
    item-text="label"
    :loading="isChangeStatusLoading"
    hide-details
    solo
    dense
  >
    <template #selection="{ item }">
      <span>
        <v-icon class="mr-2" size="20" :color="item.color"> mdi-circle </v-icon>
        {{ item.label }}
      </span>
    </template>
    <template #item="{ item }">
      <span>
        <v-icon class="mr-2" size="20" :color="item.color"> mdi-circle </v-icon>
        {{ item.label }}
      </span>
    </template>
  </v-select>
</template>

<script>
import ordersService from "../../services/orders.service";

import { ORDER_STATUSES } from "../../config";

export default {
  name: "VOrderStatusPicker",

  props: ["order"],

  data() {
    return {
      isChangeStatusLoading: false,
    };
  },

  computed: {
    orderStatuses() {
      return ORDER_STATUSES.map((status) => ({
        ...status,
        label: this.$t(`Orders.${status.label}`),
      }));
    },
  },

  methods: {
    async handleChangeStatus(status, orderId) {
      this.isChangeStatusLoading = true;
      await ordersService.updateById(orderId, { status });
      this.isChangeStatusLoading = false;

      this.$emit("change", { status, orderId });
    },
  },
};
</script>