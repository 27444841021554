<template>
  <div class="orders-list">
    <!-- <div class="orders-list-header">
      <v-orders-bulk-actions
        :selected="selected"
        @bulk:change-status="handleBulkChangeStatus"
        @bulk:delete="handleBulkDelete"
      />
    </div> -->
    <v-data-table
      class="elevation-1"
      v-model="selected"
      :headers="headers"
      :items="orders"
      :options.sync="options"
      :items-per-page="25"
      :server-items-length="total"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      sort-by="sentAt"
      :sort-desc="true"
      item-key="id"
      show-expand
    >
      <template v-slot:[`item.sentAt`]="{ value }">
        {{ value | dateOrderFormat }}
      </template>
      <template v-slot:[`item.tableNumber`]="{ item }">
        {{ getTableNamber(item) }}
      </template>
      <template v-slot:[`item.totalPrice`]="{ item, value }">
        <span>{{ $options.filters.normalizePrice(value) + " " + (item.priceCurrency ? item.priceCurrency : "") }}</span>
        <v-chip
          v-if="isValidateTotalPrice(value, item.validatedPrice)"
          class="ml-2"
          color="red"
          text-color="white"
          label
          small
        >
          ERROR
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item: order }">
        <td :colspan="headers.length">
          <div class="order-additional-info">
            <div v-if="order.takeAway && order.orderType === 'TAKE-AWAY'" class="take-away-grid">
              <div class="take-away-item">
                <b>Customer Name</b>
                <p>{{ order.takeAway.customerName }}</p>
              </div>
              <div class="take-away-item">
                <b>Customer Phone</b>
                <p>
                  <a :href="`tel:${order.takeAway.tel.replace(/[\s\-]+/g, '')}`">
                    {{ order.takeAway.tel }}
                  </a>
                </p>
              </div>
              <div class="take-away-item">
                <b>Time</b>
                <p>{{ order.takeAway.takeAwayTime | dateOrderFormat }}</p>
              </div>
            </div>
            <div v-if="order.comment">
              <b>Comment</b>
              <p>{{ order.comment }}</p>
            </div>
          </div>

          <table class="table-items">
            <tr>
              <th>{{ $t("Menu.ItemID") }}</th>
              <th>{{ $t("GlobalUI.Title") }}</th>
              <th>{{ $t("Menu.Options") }}</th>
              <th>{{ $t("Menu.Extras") }}</th>
              <th>{{ $t("GlobalUI.Comment") }}</th>
              <th>{{ $t("GlobalUI.Quantity") }}</th>
              <th>{{ $t("GlobalUI.Price") }}</th>
            </tr>
            <tr v-for="({ item, quantity }, idx) in order.items" :key="`${item.id}-${idx}`">
              <td>{{ item.internalId || "–" }}</td>
              <td>{{ item.title[appSettings.defaultLanguage] }}</td>
              <td>
                <template v-for="option in item.options">
                  <span v-if="option.selected" :key="option.id">
                    {{ option.title[appSettings.defaultLanguage] || '–' }}
                  </span>
                </template>
              </td>
              <td>
                <template v-if="item.extras.length">
                  <span class="d-block" v-for="extra in filterSelectedExtras(item.extras)" :key="extra.id">
                    {{ extra.title[appSettings.defaultLanguage] }}
                    {{ extra.single ? " — 1x" : "" }}
                    {{ extra.double ? " — 2x" : "" }}
                  </span>
                </template>
                <template v-else>–</template>
              </td>
              <td>{{ item.comment || "—" }}</td>
              <td>{{ quantity }}</td>
              <td>{{ $options.filters.normalizePrice(quantity * item.priceValue) }} {{ order.priceCurrency }}</td>
            </tr>
          </table>
        </td>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-order-status-picker :order="item" @change="handleChangeStatus" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VOrderStatusPicker from "./VOrderStatusPicker";
// import VOrdersBulkActions from "./VOrdersBulkActions.vue";

import ordersService from "../../services/orders.service";

export default {
  name: "VOrdersList",

  components: { VOrderStatusPicker },

  data() {
    return {
      orders: [],
      options: {},
      total: 0,
      isLoading: true,

      selected: [],
    };
  },

  watch: {
    newOrders(orders) {
      if (this.options.page === 1) {
        const newOrder = orders[0];
        this.orders.unshift(newOrder);
      }
    },
    options: {
      handler() {
        this.fetchOrders();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      newOrders: "orders/orders",
      locale: "lang/locale",
      appSettings: "app/settings",
    }),

    headers() {
      return [
        {
          text: this.$t("Orders.OrderID"),
          value: "internalId",
          align: "center",
          sortable: false,
          width: 80,
        },
        { text: this.$t("Orders.SendAt"), value: "sentAt", align: "center" },
        { text: this.$t("Orders.TableId"), value: "tableNumber", align: "center", sortable: false },
        { text: this.$t("Orders.Total"), value: "totalPrice", align: "center" },
        {
          text: this.$t("Orders.NumberOfDishes"),
          value: "numberOfDishes",
          align: "center",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("Orders.Status"),
          value: "status",
          align: "center",
          sortable: false,
          width: 200,
        },
        { text: "", value: "data-table-expand" },
      ];
    },

    getTableNamber() {
      return (order) => {
        let result = null;
        if (order.orderType === "DINE-IN") {
          result = order.tableNumber;
        } else if (order.orderType === "TAKE-AWAY") {
          result = this.$t("Settings.OrderChannelsTakeAway");
        }
        return result || "—";
      };
    },
  },

  methods: {
    isValidateTotalPrice(price, validatedPrice) {
      return this.$options.filters.normalizePrice(price) !== this.$options.filters.normalizePrice(validatedPrice);
    },

    filterSelectedExtras(extras) {
      return extras.filter(extra => extra.single || extra.double);
    },

    async fetchOrders() {
      this.isLoading = true;
      const { page, itemsPerPage: perPage, sortBy, sortDesc } = this.options;

      const { orders, total } = await ordersService.getAll({
        query: { page, perPage, sortBy, sortDesc },
      });

      this.orders = orders;
      this.total = total;

      this.isLoading = false;
    },

    async handleChangeStatus({ orderId, status }) {
      this.orders = this.orders.map((order) =>
        order.id === orderId ? { ...order, status } : order,
      );
    },
    async handleBulkChangeStatus({ orderIds, status }) {
      this.orders = this.orders.map((order) =>
        orderIds.includes(order.id) ? { ...order, status } : order,
      );

      this.selected = [];
    },

    async handleBulkDelete() {
      await this.fetchOrders();
      this.selected = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.orders-list-header {
  display: grid;
  grid-template-columns: 300px;
  margin-bottom: 20px;
}

.take-away-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.take-away-item {
  margin-right: 30px;
  &:last-of-type {
    margin-right: 0;
  }
}

.table-items {
  width: 100%;
  margin: 15px 0;
  border-collapse: collapse;
  border: none;

  th {
    font-size: 0.75rem;
    text-align: left;
  }

  th,
  td {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>